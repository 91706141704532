import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbAuthSimpleToken, NbAuthService } from '@nebular/auth';
import { HttpClient } from '@angular/common/http';
import { NbToastrService, NbDialogService, NbComponentStatus } from '@nebular/theme';
import { EditDialogComponent } from '../edit-dialog/edit-dialog.component';
import { VeemService } from '../veem/services/veem.service';

@Component({
  selector: 'ngx-migrate-link',
  templateUrl: './migrate-link.component.html',
  styleUrls: ['./migrate-link.component.scss']
})
export class MigrateLinkComponent implements OnInit {

  public renderValue;

  @Input() value;
  @Input() rowData: any;
  user: NbAuthSimpleToken;
  hasBeenClicked: boolean = false;
  buttonClicked = false;
  editData: any;
  result: any[] = [];
  @Output() updateResult = new EventEmitter<{ param1: any, param2: any }>();
  customerEmail = '';
  constructor(
    private http: HttpClient,
    private toastrService: NbToastrService,
    private authService: NbAuthService,
    private veemService: VeemService,
    private dialogService: NbDialogService,
  ) {

    this.authService.onTokenChange()
      .subscribe((token: NbAuthSimpleToken) => {

        if (token.isValid()) {
          this.user = token
        }

      });
  }

  ngOnInit() {
    this.renderValue = this.value;
    this.customerEmail = this.rowData.customerEmail;
  }

  // onMigrateButtonClick() {
  //   this.transferVeemInvoice();
  // }

  transferVeemInvoice() {
    this.hasBeenClicked = true;
    this.rowData.type = this.value;
    this.rowData.isUpdateEmail = false;
    this.dialogService.open(EditDialogComponent, {
      context: {
        data: this.rowData
      },
    }).onClose.subscribe(data => {
      if (!data.link) {
        this.hasBeenClicked = false;
      }
      this.updateResult.emit({ param1: this.rowData, param2: data });
    });
  }
  onMigrateButtonClick(value) {
    switch (value) {
      case "SALES_INVOICE":
      case "PURCHASE_INVOICE":
      case "PRODUCT":
      case "CONTACT":
        this.migrateDeskeraId();
        break;
    }
  }

  migrateDeskeraId() {
    this.hasBeenClicked = true;
    this.rowData.type = this.value;
    this.rowData.isUpdateEmail = false;
    this.dialogService.open(EditDialogComponent, {
      context: {
        data: this.rowData
      },
    }).onClose.subscribe(data => {
      if (!data.link) {
        this.hasBeenClicked = false;
      }
      this.updateResult.emit({ param1: this.rowData, param2: data });
    });
  }
}