import { Component, OnInit, Input, Inject } from '@angular/core';
import { NbDialogRef, NbToastrService, NB_DIALOG_CONFIG, NbComponentStatus } from '@nebular/theme';
import { VeemService } from '../veem/services/veem.service';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { DeskeraConstants } from '../deskera/utils/deskera-constants';
import { TenantService } from '../../shared/services/tenant.service';
import { ITenants } from '../../model/tenant';
import { NumberUtil } from '../../common-utils/numberUtil';
@Component({
  selector: 'ngx-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent implements OnInit {
  constants = DeskeraConstants;
  @Input() data: any = [];
  value = "";
  rowData: any;
  configForm: FormGroup;
  tenantInfo: ITenants;
  isSubmit = false;
  amountWithFormat: string;
  constructor(public dialogRef: NbDialogRef<EditDialogComponent>,
    private toastrService: NbToastrService,
    private veemService: VeemService,
    private tenantService: TenantService,
    private fb: FormBuilder,

    @Inject(NB_DIALOG_CONFIG) dialogData: any) {

      this.configForm = this.fb.group({
        phone: new FormControl('', [Validators.required, Validators.pattern("^[0-9]+")] ),
        email: new FormControl('', [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$")] ),
        isUpdateEmail: new FormControl(false),
      });
      this.getTenantInfo();
     }

  ngOnInit() {
    this.value = this.data['type'];
    this.rowData = this.data;
    this.configForm.controls.email.patchValue(this.data.customerEmail);
    this.configForm.controls.isUpdateEmail.patchValue(this.data.isUpdateEmail);
  }
  cancel() {
    this.dialogRef.close('');
  }

  getTenantInfo(): void {
		this.tenantService.get().subscribe((tenantResponse: ITenants) => {
        this.tenantInfo = tenantResponse;
        this.amountWithFormat = this.totalAmountValue(this.data.totalAmount);
    });
	}

  totalAmountValue(cell) {
    return NumberUtil.formatNumberWithCurrency(cell, this.tenantInfo.decimalScale, this.tenantInfo.numberFormat);
  }

  submit() {
    if (this.configForm.invalid) {
      this.configForm.controls.phone.markAsDirty();
      return;
    }
    this.isSubmit = true;
    let requestData = this.configForm.value;
    if (this.value === "SALES_INVOICE") {
      requestData['deskeraSalesInvoiceCode'] = this.rowData.invoiceCode
    } else if (this.value === "PURCHASE_INVOICE" ) {
      requestData['deskeraPurchaseInvoiceCode'] = this.rowData.invoiceCode
    }

    this.veemService.migrateItem(requestData, this.value).subscribe(
      (data: any) => {
        data["updateEmail"] = requestData["isUpdateEmail"]
        data["customerEmail"] = requestData["email"]
        this.dialogRef.close(data);
        this.isSubmit = false;
      }, error => {
        const warningStatus: NbComponentStatus = 'danger';
        const config = {
          status: warningStatus,
        };
        this.toastrService.show(error.error.message, "", config);
        this.isSubmit = false;
      }
    )

  }


}
