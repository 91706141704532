import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NbAuthService, NbAuthSimpleToken } from '@nebular/auth';
import { VeemService } from '../veem/services/veem.service';
import { NbToastrService, NbDialogService  } from '@nebular/theme';
import { EditDialogComponent } from '../edit-dialog/edit-dialog.component';


@Component({
  templateUrl: './migrate-button.component.html',
})
export class MigrateButtonComponent implements OnInit {

    public renderValue;

    @Input() value;
    @Input() rowData: any;
    user: NbAuthSimpleToken;
    hasBeenClicked: boolean = false;
    buttonClicked = false;
    customerEmail = '';
    @Output() updateResult = new EventEmitter<{ param1: any, param2: any }>();


    constructor(
        private http: HttpClient,
        private veemService: VeemService,
        private toastrService: NbToastrService,
        private authService: NbAuthService,
        private dialogService: NbDialogService,
    ) {

        this.authService.onTokenChange()
            .subscribe((token: NbAuthSimpleToken) => {

            if (token.isValid()) {
                this.user = token
            }

        });
    }

    ngOnInit() {
        this.renderValue = this.value;
        this.customerEmail = this.rowData.customerEmail;
    }

    onMigrateButtonClick(value){
        switch(value){
            case "SALES_INVOICE":
            case "PURCHASE_INVOICE":
            case "PRODUCT":
            case "CONTACT":
                this.migrateDeskeraId();
                break;
        }
    }

    migrateDeskeraId(){
        this.hasBeenClicked = true;
        this.rowData.type = this.value;
        this.rowData.isUpdateEmail = false;
        this.dialogService.open(EditDialogComponent, {
            context: {
                data: this.rowData
            },
        }).onClose.subscribe(data => {
            if (!data.link) {
                this.hasBeenClicked = false;
            }
            this.updateResult.emit({ param1: this.rowData, param2: data });
        });


    }

}