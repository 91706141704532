export class NumberUtil {
    static DEFAULT_DECIMAL_SCALE = 2;
    static DEFAULT_NUMBER_FORMAT = "us";

    static NUMBER_FORMAT_LOCALE = {
        in: "en-IN",
        es: "es-ES",
        fr: "fr-FR",
        us: "en-US"
    };
    static getNumberLocale(numberFormat) {
        return this.NUMBER_FORMAT_LOCALE[numberFormat] || this.NUMBER_FORMAT_LOCALE[this.DEFAULT_NUMBER_FORMAT];
    }
    
    static formatNumberWithLocale(number, decimalScale = this.DEFAULT_DECIMAL_SCALE, numberFormat = this.DEFAULT_NUMBER_FORMAT) {
        const locale = this.getNumberLocale(numberFormat);
        return new Number(number).toLocaleString(locale, { minimumFractionDigits: decimalScale });
    }
    
    static formatNumber(number, decimalScale = this.DEFAULT_DECIMAL_SCALE, numberFormat = this.DEFAULT_NUMBER_FORMAT) {
        return this.formatNumberWithLocale(number, decimalScale, numberFormat);
    }
    static formatNumberWithCurrency(numberWithCurrency, decimalScale = this.DEFAULT_DECIMAL_SCALE, numberFormat = this.DEFAULT_NUMBER_FORMAT) {
        const tokens = numberWithCurrency.split(" ");
        const currency = tokens[0];
        const number = tokens[1];
        const formattedNumber = this.formatNumber(number, decimalScale, numberFormat);
        return `${currency} ${formattedNumber}`;
    }
}
