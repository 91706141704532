import { Injectable } from '@angular/core';
import { Subject, Observable, of, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

export const INVOICE_CODE = 'saleInvoiceCode';

@Injectable({
    providedIn: 'root',
})
export class VeemService {
    userLoggedInSubject = new Subject<any>();
    userLoggedInObservable: Observable<any>;
    constructor(
        private http: HttpClient
    ) {
    }

    checkveemConnected() {
        return this.http.get(environment.apiUrl + "/veem-connect/veem/redirecturl");
    }

    connectShop(shop) {
        return this.http.get(environment.apiUrl + "/veem-connect/veem/redirecturl?");
    }

    disconnect() {
        return this.http.get(environment.apiUrl + "/veem-connect/deskera/disconnect");
    }

    migrateItem(body, type) {
        let url = '';
        if (type === "SALES_INVOICE") {
            url = '/veem-connect/deskera/invoice';
        } else {
            url = '/veem-connect/deskera/pay';
        }
        console.log(body);
        return this.http.post(environment.apiUrl + url, body);
    }
}
