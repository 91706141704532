export const DeskeraConstants = {

    connectDeskera: "Connect Deskera",
    connectVeem: "Connect Veem",
    deskeraConfig: "Deskera Config",
    sync: "Sync",
    connectDeskeraAcc: "Connect Your Deskera Books Account",
    deskeraConnectInfo: "You need to connect your Deskera Books account first by authenticating, which will sync the Invoices between Deskera Books and Veem connect app.",
    veemConnectInfo: "In this step you need to connect your Veem account, you will be redirected to the Veem login page after you click on connect. Please use your existing Veem account or signup for new account before starting this process.",
    syncInfo: "Your existing Invoices that are due for payment are already synced from the Deskera Books account",
    configUpdated: "Configs Updated",
    veemConnected: "Your Veem account is connected now",
    configs: "Configs",
    salesSendMsg: "Do you want to send the Invoice ",
    purchaseSendMsg: "Do you want to initiate payment for Bill ",
    DISCONNECT: "Disconnect",
    DISCONNECT_ACCOUNTS:"Disconnect Accounts",
    DISCONNECT_SUCCESS: "Disconnected successfully",
    DISCONNECT_FAIL: "Failed to disconnect accounts",
    CANCEL: "Cancel",
    CONFIRM : "Confirm",
    DISCONNECT_CONFIRMATION:"All the connected accounts will be disconnected. <br><br>All existing Veem invoices will remain unaffected, although any payment status update on Veem pending payments will not reflect in Deskera Books.",
    veemAccHelpText: "Select COA which will be used in Deskera Books to map Veem fee for a transaction"
};
